import { template as template_bb7fd2b835e94b4cad245d9fd70e4512 } from "@ember/template-compiler";
const FKControlMenuContainer = template_bb7fd2b835e94b4cad245d9fd70e4512(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
