import { template as template_6665bc6a076d46c28e928239f4ff9892 } from "@ember/template-compiler";
const FKText = template_6665bc6a076d46c28e928239f4ff9892(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
